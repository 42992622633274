$('.show-password').click(function () {
  var password = $(this).closest('.input-group--password').find('input');
  if (password.attr("type") == 'text') {
    password.attr('type', 'password');
  } else {
    password.attr('type', 'text');
  }
});

$(".number-minus, .number-plus").on("click", function () {
  var $button = $(this);
  var inputField = $button.parent().find("input");
  var value = +inputField.val();

  $button.text() == "+" ? value++ : value--;

  if (!value) return;

  $button.parent().find("input").val(value).trigger('change');

});


var inputQuantity = [];
$(function () {
  $(".credit__number-input").each(function (i) {
    inputQuantity[i] = this.defaultValue;
    $(this).data("idx", i);
  });
  $(".credit__number-input").on("keyup", function (e) {
    var $field = $(this),
      val = this.value,
      $thisIndex = parseInt($field.data("idx"), 10);
    if (this.validity && this.validity.badInput || isNaN(val) || $field.is(":invalid")) {
      this.value = inputQuantity[$thisIndex];
      return;
    }
    if (val.length > Number($field.attr("maxlength"))) {
      val = val.slice(0, 16);
      $field.val(val);
    }
    inputQuantity[$thisIndex] = val;
  });
});


jQuery('.account-list__mobile').click(function () {
  jQuery(this).closest('.account-list').find('.account-menu').toggle(400);
  jQuery(this).closest('.account-list').toggleClass('active');

});

$('.address-wrap input, .address-wrap select').change(function () {
  if (
    ($('#address--name').val() != '') ||
    ($('#address--phone').val() != '') ||
    ($('#address--city').val() != '') ||
    ($('#address--zip').val() != '') ||
    ($('#address--address').val() != '') ||
    ($('#address--checkbox').attr('checked') ||
      ($('#address--apartment').val() != '')

    ))

  {
    $('.account-wrap__remove').addClass('active');
  } else {
    $('.account-wrap__remove').removeClass('active');
  }
});