(function () {
  const commentsBlock = $('#comments');

  commentsBlock.on('click', '.comments__item-reply', function () {
    const $comment = $(this).closest('.comments__item')

    if ($comment.hasClass('reply-active')) {
      $comment.find('.form--reply').stop().slideUp(function () {
        $(this).remove();
      });
      $comment.removeClass('reply-active')
    } else {
      commentsBlock
        .find('.comments__item')
        .removeClass('reply-active')
        .find('.form--reply')
        .remove()

      const $form = commentsBlock.find('.form--reply').first();
      const $formClone = $form.clone();
      $formClone.on('submit', function () {
        // Send comment to server
        $formClone.remove();
        $comment.removeClass('reply-active')
        // Update comments
      })
      $comment.find('.comments__item-replies').append($formClone);
      $formClone.hide().removeClass('hidden').stop().slideDown();
      $comment.addClass('reply-active')
    }
  })
})()