const $tabsNav = $('.tabs-nav');

$tabsNav.on('click', '.tabs-nav__btn', function (e) {
  e.preventDefault();
  if ($(this).parent().hasClass('active')) return;

  const tab = $(this).attr('data-tab-refer');

  const currentPage = location.origin + location.pathname;

  history.pushState({
    tab
  }, null, `${currentPage}#${tab}`);

  $(`[data-tab-id].active`).removeClass('active').hide();
  $(`[data-tab-id="${tab}"]`).stop().addClass('active').fadeIn();

  $tabsNav.find('.active').removeClass('active');
  $(this).parent().addClass('active')
})

if (history.state && history.state.tab) {
  const tabBtn = $tabsNav.find(`[data-tab-refer="${history.state.tab}"]`);
  tabBtn.trigger('click')
}

$('#tab-link').on('click', function (e) {
  e.preventDefault();
  $(`[data-tab-refer="${$(this).attr('href')}"]`).trigger('click');
})

const $smallTabs = $('.small-tabs');

$smallTabs.each(function() {
  $(this).find(`[data-tab-id]:not(.active)`).hide();
})

$smallTabs.on('click', '.small-tabs__btn', function (e) {
  e.preventDefault();
  if ($(this).hasClass('active')) return;

  const $parent = $(this).closest('.small-tabs');

  const tab = $(this).attr('data-tab-refer');

  $parent.find(`[data-tab-id].active`).removeClass('active').hide();
  $parent.find(`[data-tab-id="${tab}"]`).stop().addClass('active').fadeIn();

  $parent.find('.small-tabs__btn.active').removeClass('active');
  $(this).addClass('active')
})