(function() {
  const $customLab = $('#custom-lab');

  if (!$customLab.length) return;

  const $customLabWrapper = $customLab.find('.custom-lab__wrapper');
  const $customLabInner = $customLab.find('.custom-lab__inner');
  const $customLabTopClosed = $customLab.find('.custom-lab__top-closed');
  const $customLabTopOpened = $customLab.find('.custom-lab__top-opened');

  const $customLabImgWrapper = $('.custom-lab__step-img-wrapper');

  $customLabTopOpened.hide();

  $(window).on('scroll load', e => {
    $customLab.each(function() {
      const stickOffset = $(this).offset().top;
      const scrollOffset =
        $(window).scrollTop() + $(window).height() - $(this).outerHeight();

      if (stickOffset > scrollOffset) {
        $(this).addClass('stick');
      } else {
        $(this).removeClass('stick');
      }
    });
  });

  $customLabInner.on('scroll', e => {
    $customLabImgWrapper.each(function() {
      const stickOffsetTop = this.getBoundingClientRect().top;
      const stickPoint = $customLabInner.scrollTop() - $(this).outerHeight();

      if (stickOffsetTop > stickPoint) {
        $(this).removeClass('stick');
      } else {
        $(this).addClass('stick');
      }
    });
  });

  $customLabImgWrapper.on('click', function() {
    $(this).toggleClass('zoomed');
    $('.custom-lab__img-overlay')
      .stop()
      .fadeToggle();
  });

  const CustomLab = () => ({
    _offsetBeforeOpening: 0,
    _isOpened: false,
    _activeScreen: '',
    _currentStep: 0,
    _prices: {},
    _stepsNames: {},
    _stepsCount: {},
    _completedSteps: [],
    _formValues: [],
    _summaryOpened: false,
    selectedPutter: null,

    goToNextStep() {
      if (this._goToNextDisabled) return;

      if (this._currentStep === 0 && this._activeScreen === 'start') {
        if (!this.selectedPutter) return;

        this._currentStep = 1;
        this.showScreen();
        this.updateCompletedStepsStatus();
        this.openStep();
        this.setButtonAccessible();
      } else {
        this.closeStep();

        if (this._currentStep + 1 > this._stepsCount[this.selectedPutter]) {
          this._currentStep = this._stepsCount[this.selectedPutter] + 1;
          this.updateCompletedStepsStatus();
          this.showAddToCartBlock();
          this.toggleSummary();
        } else {
          this._currentStep++;
          this.updateCompletedStepsStatus();
          this.openStep();
          this.setButtonAccessible();
        }
      }
    },
    showAddToCartBlock() {
      this.$currentForm.find('.custom-lab__add-to-cart-block').fadeIn();
    },
    setButtonAccessible() {
      let disabled = false;

      if (this._currentStep <= this._stepsCount[this.selectedPutter]) {
        const currentStepName = this._stepsNames[this.selectedPutter][
          this._currentStep
        ];
        const currentStepField = this._formValues.find(
          field => field.name === currentStepName
        );
        const currentStepValue = currentStepField && currentStepField.value;

        if (!currentStepValue) {
          disabled = true;
        }

        this.$currentForm
          .find('.custom-lab__next-step')
          .prop('disabled', disabled);
        this._goToNextDisabled = disabled;
      }
    },
    updateCompletedStepsStatus() {
      const nextStep = this._currentStep + 1;
      const stepsCount = this._stepsCount[this.selectedPutter];
      const completedSteps = this._currentStep - 1;

      if (nextStep > stepsCount) {
        this.$currentForm
          .find('.custom-lab__steps-bottom-status')
          .addClass('completed');
      } else {
        this.$currentForm
          .find('.custom-lab__steps-bottom-status')
          .text(`${(nextStep < 10 ? '0' : '') + nextStep}/${stepsCount} STEPS`);
      }

      this.$currentForm
        .find('.custom-lab__summary-completed')
        .text(
          `${(completedSteps < 10 ? '0' : '') +
            completedSteps} of ${stepsCount} STEPS`
        );
    },
    openStep(step) {
      const stepIndex = step || this._currentStep;

      const stepItem = this.$currentForm.find(
        `[data-step-number="${stepIndex}"]`
      );

      if (!stepItem.hasClass('completed')) {
        stepItem.fadeIn();
      }

      if (stepItem.hasClass('active')) {
        // its active already
        return;
      }

      stepItem
        .addClass('active')
        .find('.custom-lab__step-content')
        .stop()
        .slideDown();
    },
    openCompletedStep(step) {
      const stepItem = this.$currentForm.find(`[data-step-number="${step}"]`);

      if (stepItem.hasClass('active')) {
        return;
      }

      const openedCompletedStep = this.$currentForm.find(
        '.custom-lab__step-item.completed.active'
      );

      if (openedCompletedStep.length) {
        const stepIdx = openedCompletedStep.data('step-number');
        this.closeStep(stepIdx);
      }

      stepItem
        .addClass('active')
        .find('.custom-lab__step-content')
        .stop()
        .slideDown();
    },
    closeStep(step) {
      const stepIndex = step || this._currentStep;

      const stepItem = this.$currentForm.find(
        `[data-step-number="${stepIndex}"]`
      );

      if (!stepItem.hasClass('active')) {
        // its non-active already
        return;
      }

      stepItem
        .addClass('completed')
        .removeClass('active')
        .find('.custom-lab__step-content')
        .stop()
        .slideUp();
    },
    open() {
      const self = this;
      if (self._isOpened) return;
      self._offsetBeforeOpening = $customLabWrapper[0].getBoundingClientRect().top;

      bodyScrollLock.disableBodyScroll($customLabInner[0]);

      $customLab.addClass('opening');

      const top = $customLab.hasClass('stick')
        ? 0
        : `-${this._offsetBeforeOpening}px`;

      $customLabTopClosed.fadeOut(300, function() {
        $customLabTopOpened.fadeIn(300);
      });

      $customLabWrapper.animate(
        {
          top
        },
        600,
        'swing',
        function() {
          $(this).css('top', '0');
          $customLab.removeClass('opening').addClass('opened');
          self.showScreen();
          self._isOpened = true;
        }
      );

      $customLabInner.stop().fadeIn();

      const $customizationScreens = $('[data-lab-screen="customization"]');

      if ($customizationScreens.length === 1 && this._currentStep === 0) {
        const putter = $customizationScreens.data('lab-putter');
        customLab.setSelectedPutter(putter);
        customLab.goToNextStep();
      }
    },
    close() {
      const self = this;
      if (!self._isOpened) return;
      bodyScrollLock.clearAllBodyScrollLocks();

      $customLab.addClass('closing').removeClass('opened');

      const fromTop = $customLab.hasClass('stick')
        ? 0
        : `-${this._offsetBeforeOpening}px`;
      const toTop = $customLab.hasClass('stick')
        ? `${this._offsetBeforeOpening}px`
        : 0;

      $customLabTopOpened.fadeOut(300, function() {
        $customLabTopClosed.fadeIn(300);
      });

      this.hideScreen();

      $customLabWrapper.css('top', fromTop).animate(
        {
          top: toTop
        },
        600,
        'swing',
        function() {
          $customLab.removeClass('closing');
          $(this).css('top', 'auto');
          self._isOpened = false;
        }
      );

      $customLabInner.stop().fadeOut();
    },
    showScreen() {
      const screenToShow = this._currentStep === 0 ? 'start' : 'customization';

      let selector = `[data-lab-screen="${screenToShow}"]`;

      if (screenToShow === 'customization') {
        selector += `[data-lab-putter="${this.selectedPutter}"]`;
      }

      if (this._activeScreen) {
        $(`[data-lab-screen="${this._activeScreen}"]`)
          .stop()
          .fadeOut(function() {
            $(selector)
              .stop()
              .fadeIn();
          });
      } else {
        $(selector)
          .stop()
          .fadeIn();
      }

      this._activeScreen = screenToShow;
    },
    hideScreen() {
      if (this._activeScreen) {
        $(`[data-lab-screen="${this._activeScreen}"]`)
          .stop()
          .fadeOut();
      }

      this._activeScreen = '';
    },
    setSelectedPutter(val) {
      this.selectedPutter = val;
      this.$currentForm = $(`[data-lab-putter="${val}"] .custom-lab__form`);
      this.updatePrice();

      $('[data-lab-screen="start"] .custom-lab__next-step').prop(
        'disabled',
        false
      );
    },
    setPrices(putterName, prices) {
      this._prices[putterName] = prices;
    },
    setStepsCount(putterName, stepsCount) {
      this._stepsCount[putterName] = stepsCount;
    },
    setStepsNames(putterName, stepsNames) {
      this._stepsNames[putterName] = stepsNames;
    },
    updatePrice() {
      if (!this.selectedPutter) return;
      let price = 0;
      let qty = 1;

      const $summaryStepsList = this.$currentForm.find(
        '.custom-lab__summary-steps-list'
      );

      this._formValues.forEach(({ name, value }) => {
        if (name === 'qty') {
          qty = value;
          return;
        }

        const option = this._prices[this.selectedPutter][name];
        if (!option) return;

        const { getTitle, getPrice } = option;

        const currency = typeof getPrice === 'function' ? +getPrice(value) : 0;
        price += isNaN(currency) ? 0 : currency;

        // Update Step value in summary block
        const stepNumber = this.$currentForm
          .find(`[data-option-name="${name}"]`)
          .data('step-number');

        if (!stepNumber || stepNumber > this._currentStep) {
          return;
        }

        const $summaryStepItem = $summaryStepsList.find(
          `[data-step-name=${name}]`
        );
        const resultPrefix = $summaryStepItem.data('result-prefix');
        const summaryText = `${resultPrefix} - ${getTitle(value)}`;
        $summaryStepItem.text(summaryText);
      });

      // const newTotalPrice = '$' + price;
      // const $totalPriceBlock = this.$currentForm.find(
      //   '.custom-lab__summary-total-price'
      // );

      // if ($totalPriceBlock.text() !== newTotalPrice) {
      //   $totalPriceBlock.stop().fadeOut(200, function() {
      //     $(this)
      //       .text(newTotalPrice)
      //       .stop()
      //       .fadeIn(200);
      //   });
      // }

      // const newSummaryPrice = '$' + (price * qty).toFixed(2);
      // const $summaryPriceBlock = this.$currentForm.find(
      //   '.custom-lab__summary-price'
      // );

      // if ($summaryPriceBlock.text() !== newSummaryPrice) {
      //   $summaryPriceBlock.stop().fadeOut(200, function() {
      //     $(this)
      //       .text(newSummaryPrice)
      //       .stop()
      //       .fadeIn(200);
      //   });
      // }
    },
    updateValues(values) {
      this._formValues = values;
      this.updatePrice();
      this.setButtonAccessible();
    },
    toggleSummary() {
      if (this._summaryOpened) {
        $('.custom-lab__overlay')
          .stop()
          .fadeOut();
        $('.custom-lab__summary-wrapper').removeClass('opened');
        setTimeout(() => {
          $('.custom-lab__summary-steps-list').hide();
        }, 500);
      } else {
        $('.custom-lab__overlay')
          .stop()
          .fadeIn();
        $('.custom-lab__summary-steps-list').show();
        $('.custom-lab__summary-wrapper').addClass('opened');
      }

      this._summaryOpened = !this._summaryOpened;
    }
  });

  const customLab = new CustomLab();

  // init prices
  $('[data-lab-screen="customization"]').each(function() {
    const putterName = $(this).data('lab-putter');

    const putterInputField = $(this).find('input[data-putter-price]');

    const putterBasePrice = +putterInputField.data('putter-price');
    const putterFieldName = putterInputField.attr('name');
    const prices = {
      [putterFieldName]: {
        getPrice: () => putterBasePrice
      }
    };
    const stepsNames = {};
    let stepsCount = 0;
    const summaryStepsList = $(this).find('.custom-lab__summary-steps-list');

    $(this)
      .find('.custom-lab__step-item')
      .each(function() {
        stepsCount++;
        const self = this;
        const stepType = $(self).data('step-type');
        const optionName = $(self)
          .find('input')
          .attr('name');
        const stepNumber = $(self).data('step-number');
        const stepTitle = $(self)
          .find('.custom-lab__step-title')
          .text();
        const resultPrefix = $(self).data('result-prefix');

        $(self).attr('data-option-name', optionName);
        stepsNames[stepNumber] = optionName;

        let getPrice = function() {};
        let getTitle = function() {};

        if (stepType === 'radio') {
          getPrice = function(val) {
            const radioInput = $(self).find(`input[value="${val}"]`);

            if (!radioInput.length) return 0;
            return radioInput.data('option-price');
          };
          getTitle = function(val) {
            const radioInput = $(self).find(`input[value="${val}"]`);

            if (!radioInput.length) return 0;
            return radioInput
              .parent()
              .find('label > span')
              .text();
          };
        } else if (stepType === 'range') {
          getPrice = function(val) {
            if (isNaN(val)) {
              console.error(
                'Provided value from range field is NaN, value - ',
                val
              );
            }
            const inputField = $(self).find(`input[type="text"]`);

            if (!inputField.length) return 0;

            const optionPrice = inputField.data('option-price');
            const ranges = optionPrice.split(';').map(set => {
              const [range, price] = set.split('=');
              const [min, max] = range.split('-');
              return {
                min,
                max,
                price
              };
            });

            for (let set of ranges) {
              if (+val >= +set.min && +val <= +set.max) {
                return set.price;
              }
            }
          };
          getTitle = function(val) {
            return val;
          };
        }
        
        summaryStepsList.append(
          `<li data-result-prefix="${stepTitle}" data-step-name="${optionName}"' data-step-number="${stepNumber}">${stepTitle}</li>`
        );
        prices[optionName] = {
          getPrice,
          getTitle
        };
      });

    customLab.setPrices(putterName, prices);
    customLab.setStepsNames(putterName, stepsNames);
    customLab.setStepsCount(putterName, stepsCount);
  });

  $('.custom-lab__form').on('change', function() {
    customLab.updateValues($(this).serializeArray());
  });

  $('.custom-lab__open-btn').on('click', function(e) {
    e.preventDefault();
    customLab.open();
  });

  $('.custom-lab__close-btn').on('click', function(e) {
    e.preventDefault();
    customLab.close();
  });

  $('.custom-lab__choose-putter').on('change', 'input[type=radio]', function(
    e
  ) {
    customLab.setSelectedPutter(e.target.value);
  });

  $('.custom-lab__next-step').on('click', function(e) {
    e.preventDefault();
    customLab.goToNextStep();
  });

  $(document).on('keypress', function(e) {
    if (e.keyCode === 13) {
      customLab.goToNextStep();
    }
  });

  $('.custom-lab__form').on('submit', function(e) {
    // e.preventDefault();
    const data = $(this).serialize();
    console.log(data);
  });

  $('.custom-lab__radio-buttons').each(function(e) {
    const childrenCount = $(this).children().length;
    if (childrenCount > 3) {
      $(this).addClass('custom-lab__radio-buttons--wrap');
    }
  });

  $(document).on('keydown', function(e) {
    if (e.keyCode === 27 && customLab._isOpened) {
      customLab.close();
    }
  });

  // remove
  // customLab.open();
  // customLab.setSelectedPutter('blad-putter');
  // customLab.goToNextStep();

  $('.custom-lab__step-item .custom-lab__step-title').on('click', function() {
    const stepItem = $(this).closest('.custom-lab__step-item');

    if (!stepItem.hasClass('completed')) {
      return;
    }
    const stepIdx = stepItem.data('step-number');

    if (stepItem.hasClass('active')) {
      customLab.closeStep(stepIdx);
    } else {
      customLab.openCompletedStep(stepIdx);
    }
  });

  $('.custom-lab__summary-toggle').on('click', () => customLab.toggleSummary());
  $('.custom-lab__overlay').on('click', () => customLab.toggleSummary());
})();

$(window).on('load', function() {
  $('.custom-lab__step-range-wrapper').each(function() {
    const inputField = $(this).find('input');
    const isProd = $(this).hasClass('tmcp-field-wrap');

    const slider = $(this).find('.custom-lab__step-slider')[0];

    const { max, min, step, decimals } = slider.dataset;

    const valuesCount = (max - min) / step;
    const width = Math.ceil((valuesCount / 7) * 100);

    const inner = $(this).find('.custom-lab__step-range-inner');
    inner.css('width', (width > 100 ? width : 100) + '%');

    const sliderOptions = {
      start: +min,
      // connect: true,
      step: +step,
      padding: +step,
      pips: {
        mode: 'steps',
        density: 10,
        behaviour: 'tap',
        format: {
          to: function(val) {
            return (+val).toFixed(+decimals);
          },
          from: function(val) {
            return (+val).toFixed(+decimals);
          }
        }
      },
      range: {
        min: +min - +step,
        max: +max + +step
      }
    };

    !isProd && noUiSlider.create(slider, sliderOptions);

    const wrapper = $(this);
    const val = +step < 1 ? (+inputField.val()).toFixed(1) : inputField.val();

    wrapper.find('.noUi-value').each(function() {
      const text = $(this).text();
      if (text === val) {
        $(this).addClass('active');
      }
    });

    slider.noUiSlider.on('update', function(values, handle) {
      const value = +values[handle];
      inputField.val(value);
      inputField.trigger('change');

      const outer = wrapper.find('.custom-lab__step-range-outer');

      const total = outer[0].scrollWidth - outer[0].offsetWidth;
      const scrollLeft = total * ((value - min) / 15);

      outer.find('.noUi-value.active').removeClass('active');
      // outer.find(`[data-value="${value}"]`).addClass('active');

      const formattedValue = +step < 1 ? value.toFixed(1) : value + '';
      outer.find('.noUi-value').each(function() {
        const text = $(this).text();

        if (text === formattedValue) {
          $(this).addClass('active');
        }
      });

      outer.stop().animate({
        scrollLeft
      });
    });

    const prevHandler = e => {
      e.preventDefault();
      const currentVal = slider.noUiSlider.get();

      if (currentVal > min) {
        slider.noUiSlider.set(+currentVal - +step);
      }
    };

    const nextHandler = e => {
      e.preventDefault();
      const currentVal = slider.noUiSlider.get();

      if (currentVal < max) {
        slider.noUiSlider.set(+currentVal + +step);
      }
    };

    wrapper.find('.custom-lab__step-range-prev').on('click', prevHandler);
    wrapper.find('.custom-lab__step-range-next').on('click', nextHandler);
  });
});

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
