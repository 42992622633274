$('.events-slider__list').slick({
  nextArrow: '.events-slider .slider-next-arrow',
  prevArrow: '.events-slider .slider-prev-arrow',
  autoplay: true,
  autoplaySpeed: 5000,
  mobileFirst: true,
  dotsClass: 'slider-dots slider-dots--type-1',
  appendDots: '.events-slider',
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        dots: true
      }
    },
  ]
})