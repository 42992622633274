const dummyLocations = [{
  country: 'United States',
  city: 'Alabama',
  name: 'Birmingham Golf Co c/o Bill Adams PGA Master Instructor',
  website: 'www.birminghamgolf.co/',
  phone: '123.456.789',
  address: '3504 Mill Springs Road, Mountain Brook 35223',
  coords: {
    lat: 33.514209,
    lng: -86.7009160
  }
}, {
  country: 'United States',
  city: 'Alabama',
  name: 'Shoal Creek Club',
  website: 'www.shoalcreekclub.com/',
  phone: '555.555.555',
  address: '102 New Williamsburg Drive, Shoal Creek AL 35242',
  coords: {
    lat: 33.427650,
    lng: -86.623473,
  }
}, {
  country: 'United States',
  city: 'CALIFORNIA',
  name: 'Alameda GolfWorks',
  website: 'www.alamedagolfworks.com/',
  phone: '555.555.555',
  address: '1731 Clement Ave, Alameda, CA 94501',
  coords: {
    lat: 37.775901,
    lng: -122.252132,
  }
}, {
  country: 'Australia',
  city: 'Victoria',
  name: 'Contact: William Presse',
  phone: '+61 411 524 557',
  email: 'william@directedforce.com',
  additional: 'Skype: Bill.Presse1'
}, {
  country: 'Canada',
  city: 'Ontario',
  name: 'Peter Boyce Academy, Strathroy',

  additional: 'Contact: Peter Boyce PGA',
  website: 'www.alamedagolfworks.com/',
  phone: '519 247 3005',
  email: 'peter@peterboycegolf.com',

  address: '10625 County Rd 12, Strathroy, ON N7G 3H7',
  coords: {
    lat: 43.049620,
    lng: -81.692565,
  }
}];

(function () {
  if (!$('#interactive-map').length) return;

  const mapStyles = [{
      elementType: 'geometry',
      stylers: [{
        color: '#DBDBDB'
      }]
    },
    // {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
    // {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
    // {
    //   featureType: 'administrative.locality',
    //   elementType: 'labels.text.fill',
    //   stylers: [{color: '#d59563'}]
    // },
    // {
    //   featureType: 'poi',
    //   elementType: 'labels.text.fill',
    //   stylers: [{color: '#d59563'}]
    // },
    // {
    //   featureType: 'poi.park',
    //   elementType: 'geometry',
    //   stylers: [{color: '#263c3f'}]
    // },
    // {
    //   featureType: 'poi.park',
    //   elementType: 'labels.text.fill',
    //   stylers: [{color: '#6b9a76'}]
    // },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{
        color: '#bbbbbb'
      }]
    }, {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{
        color: '#aaaaaa'
      }]
    }, {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{
        color: '#4a4a4a'
      }]
    }, {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{
        color: '#bbbbbb'
      }]
    }, {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{
        color: '#aaaaaa'
      }]
    }, {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{
        color: '#4a4a4a'
      }]
    },
    // {
    //   featureType: 'transit',
    //   elementType: 'geometry',
    //   stylers: [{color: '#2f3948'}]
    // },
    // {
    //   featureType: 'transit.station',
    //   elementType: 'labels.text.fill',
    //   stylers: [{color: '#d59563'}]
    // },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{
        color: '#ffffff'
      }]
    }, {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{
        color: '#515c6d'
      }]
    }, {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{
        color: '#17263c'
      }]
    }
  ]

  const svgMarker = {
    path: 'M15 36L4.4 25.5C1.6 22.6 0 18.9 0 14.9S1.6 7.2 4.4 4.4C7.2 1.6 11 0 15 0s7.8 1.6 10.6 4.4c5.8 5.8 5.8 15.3 0 21.1L15 36zm0-27.7c-3.6 0-6.6 2.9-6.6 6.6 0 3.6 3 6.6 6.6 6.6 3.6 0 6.6-2.9 6.6-6.6 0-3.7-3-6.6-6.6-6.6z',
    fillColor: '#54bae2',
    fillOpacity: 1,
    scale: 1,
    strokeColor: '#9b9b9b',
    anchor: new google.maps.Point(16.5, 38),
  };

  const svgMarkerHighlighted = {
    ...svgMarker,
    fillColor: '#549ce2',
  };

  const mapApi = {
    markers: [],
    country: 'Select country',
    city: '',
    geocoder: new google.maps.Geocoder(),

    getLocations() {
      const self = this;
      // AJAX get locations

      setTimeout(() => {
        // Then set them
        self.setLocations(dummyLocations);
      }, 1000);
    },

    changeCity(city) {
      const select = $(`.accordeon__item[data-city="${city}"]`)
        .closest('[data-related-country]')
        .find('.location-filter__select-city');
      select.val(city)
      select.trigger('change');
    },

    changeCountry(country, withoutFit) {
      $('#select-country').val(country);
      $('#select-country').trigger('change', {
        withoutFit
      });
    },

    viewChangeCity(city) {
      if (this.city === city) {
        if ($(window).width() > 991) {
          $('.accordeon__item.active')
            .removeClass('active')
            .find('.accordeon__item-content')
            .stop()
            .slideUp();
          this.city = null;
        }
        return;
      };

      this.city = city;

      $('.accordeon__item.active')
        .removeClass('active')
        .find('.accordeon__item-content')
        .stop()
        .slideUp();

      $(`.accordeon__item[data-city="${this.city}"]`)
        .addClass('active')
        .find('.accordeon__item-content')
        .stop()
        .slideDown();
    },

    viewChangeCountry(country, extra = {}) {
      if (this.country === country) return;
      this.country = country;

      if (!extra.withoutFit && country.toLowerCase() !== 'select country') {
        this.geocodeAddress({
          address: country
        }, (res) => {
          this.fitPlaces([res[0]])
        })
      }

      $(`[data-related-country].active`).removeClass('active').hide();
      $(`[data-related-country="${this.country}"]`).addClass('active').fadeIn()
    },

    calculateDistance(p1, p2) {
      const distInMeters = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
      const distInMiles = Math.floor(distInMeters / 1000 * 0.621371);
      return distInMiles;
    },

    calculateDistances() {
      const self = this;

      const from = self.searchLocation || self.userLocation;
      if (!from) return;

      self.markers.forEach(marker => {
        if (!marker.position) return;
        const distance = self.calculateDistance(marker.position, from)

        $(`.locations-list__item[data-loc-name="${marker._locName}"] .locations-list__item-distance`)
          .html(`<b>${distance}</b>miles`)
      })
    },

    initMap() {
      const self = this;

      self.map = new google.maps.Map(document.getElementById('interactive-map'), {
        center: {
          lat: 22.419676,
          lng: -31.812810,
          // lat: 19.689491,
          // lng: 6.469852,
        },
        mapTypeControl: false,
        mapTypeId: 'roadmap',
        zoom: 2,
        styles: mapStyles
      });

      // Create the search box and link it to the UI element.
      var input = document.getElementById('map-search');
      var searchBox = new google.maps.places.SearchBox(input);

      $('.map__search-box').on('submit', function (e) {
        e.preventDefault()
      })

      // Bias the SearchBox results towards current map's viewport.
      self.map.addListener('bounds_changed', function () {
        searchBox.setBounds(self.map.getBounds());
      });

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', function () {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        self.changeSearchLocation(places);
      });
    },

    fitPlaces(places) {
      const bounds = new google.maps.LatLngBounds();

      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      this.map.fitBounds(bounds);
    },

    geocodeAddress(params, cb) {
      this.geocoder.geocode(params, function (results, status) {
        if (status === 'OK') {
          cb(results);
        } else {
          cb(null);
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    },

    setUserLocation() {
      const self = this;
      // Get user geolocation
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(location => {
          console.log(location)
          const userCoords = {
            lat: location.coords.latitude,
            lng: location.coords.longitude
          }
          self.geocodeAddress({
            location: userCoords
          }, (results) => {
            self.userLocation = results[0] && results[0].geometry && results[0].geometry.location;
            self.calculateDistances();
          })
        })
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },

    changeSearchLocation(places) {
      this.fitPlaces(places);

      if (places[0].geometry && places[0].geometry.location) {
        const searchlocation = places[0].geometry.location;
        this.searchLocation = searchlocation;
        this.calculateDistances()
      }
    },

    highlightLocation(locName) {
      const locItem = $(`.locations-list__item[data-loc-name="${locName}"]`);
      locItem.addClass('highlighted');

      setTimeout(() => {
        locItem.removeClass('highlighted');
      }, 2000);
    },

    findMarker(locName) {
      return this.markers.filter(marker => marker._locName === locName)[0];
    },

    highlightMarker(locName) {
      const marker = this.findMarker(locName);

      if (!marker) return;
      marker.setIcon(svgMarkerHighlighted)
      marker.setAnimation(google.maps.Animation.BOUNCE);
    },

    removeHiglightFromMarker(locName) {
      const marker = this.findMarker(locName);

      if (!marker) return;
      marker.setIcon(svgMarker)
      marker.setAnimation(null);
    },

    animateMarker(locName) {
      const marker = this.findMarker(locName);

      if (!marker) return;
      marker.setAnimation(google.maps.Animation.BOUNCE);
    },

    stopAnimateMarker(locName) {
      const marker = this.findMarker(locName);

      if (!marker) return;
      marker.setAnimation(null);
    },

    zoomToLoc(locName) {
      const marker = this.findMarker(locName);

      if (!marker) return;
      this.map.setZoom(18);
      this.map.panTo(marker.position);
    },

    init() {
      const self = this;

      self.initMap();
      self.getLocations();
      self.setUserLocation();

      $('#select-country').on('change', (e, extra) => {
        this.viewChangeCountry(e.target.value, extra)
      });
      const $locationFilter = $('#location-filter');

      $locationFilter.on('change', '.location-filter__select-city', e => this.viewChangeCity(e.target.value));

      $locationFilter.on('click', '.accordeon__item-header', function () {
        const city = $(this).parent().attr('data-city');
        self.changeCity(city);
      })

      $locationFilter.on('click', '.locations-list__item-btn', function () {
        const locName = $(this).closest('.locations-list__item').attr('data-loc-name');
        self.zoomToLoc(locName);
      })

      $locationFilter.on('mouseenter', '.locations-list__item', function () {
        const locName = $(this).attr('data-loc-name');
        self.highlightMarker(locName);
      })

      $locationFilter.on('mouseleave', '.locations-list__item', function () {
        const locName = $(this).attr('data-loc-name');
        self.removeHiglightFromMarker(locName);
      })
    },

    setLocations(locations) {
      this.locations = locations;
      const self = this;

      const countries = {};

      locations.forEach(loc => {
        !countries[loc.country] && (countries[loc.country] = {});
        !countries[loc.country][loc.city] && (countries[loc.country][loc.city] = []);

        countries[loc.country][loc.city].push(loc);
      })

      for (let country in countries) {
        // Add option to select-country
        const newOption = new Option(country, country, false, false);
        $('#select-country').append(newOption).trigger('change');

        const countryWrapper = $('<div class="location-filter__country-wrapper">');
        countryWrapper.attr('data-related-country', country);
        const selectCityWrapper = $('<div class="location-filter__select-wrapper location-filter__select-wrapper--city">');
        const selectCity =
          $(`<select style="width: 100%" id="select-city-${country.toLocaleLowerCase().replace(' ', '-')}" class="styled-select location-filter__select-city">`)
        selectCity.append('<option>Select city/state</option>')

        const locationsAccordeon = $('<div class="accordeon accordeon--locations">')
        const locationsAccordeonList = $('<ul class="accordeon__list">')

        const currentCountry = countries[country];

        for (let city in currentCountry) {
          const locationsInCity = currentCountry[city];

          const newOption = new Option(`${city} - ${locationsInCity.length} locations`, city, false, false);
          selectCity.append(newOption);

          const accordeonItem = $(`<li data-city="${city}" class="accordeon__item">`)

          const accordeonHeader = $('<div class="accordeon__item-header">').append(`<b class="accordeon__item-name">${city} <small>${locationsInCity.length} Locations</small></b>`)
          const accordeonContent = $('<div class="accordeon__item-content">')
          const locationsList = $('<ul class="locations-list">');

          locationsInCity.forEach(loc => {
            const html = `
              <li class="locations-list__item" data-loc-name="${loc.name}" ${loc.coords ? `data-lat="${loc.coords.lat}" data-lng="${loc.coords.lng}"` : ''}>
                  <div class="locations-list__item-text">
                    <b>${loc.name}</b> <br>
                    ${loc.website ? `<a target="_blank" href="${loc.website}">${loc.website}</a> <br>` : ''}
                    ${loc.address ? `<a target="_blank" href="https://www.google.com/maps/dir//${loc.address}">${loc.address}</a> <br>` : ''}
                    ${loc.phone ? `Phone: <a href="tel:${loc.phone}">${loc.phone}</a> <br>` : ''}
                    ${loc.email ? `Email: <a href="mailto:${loc.email}">${loc.email}</a> <br>` : ''}
                    ${loc.additional ? loc.additional : ''}
                  </div>
                  <div class="locations-list__item-side">
                    ${loc.coords ? 
                      `<button title="Show on map" type="button" class="locations-list__item-btn">
                        <svg>
                          <use xlink:href='svg-sprite/sprite.svg#icon-pin'></use>
                        </svg>
                      </button>
                      <span class="locations-list__item-distance"></span>`
                     : ''}
                  </div>
                </li>`;

            locationsList.append(html)

            if (loc.coords && loc.coords.lat && loc.coords.lng) {
              const marker = new google.maps.Marker({
                position: new google.maps.LatLng(loc.coords.lat, loc.coords.lng),
                map: self.map,
                icon: svgMarker,
                title: loc.name
              });

              marker.addListener('click', function (e) {
                if (self.city !== loc.city) {
                  self.changeCountry(loc.country, true);
                  self.changeCity(loc.city);
                }
                self.highlightLocation(loc.name)
              })

              marker._locName = loc.name;

              self.markers.push(marker);

              marker.addListener('mouseover', function () {
                self.highlightMarker(loc.name);
                self.highlightLocation(loc.name);
              });

              marker.addListener('mouseout', function () {
                self.removeHiglightFromMarker(loc.name);
              });
            }
          })

          accordeonContent.append(locationsList)
          accordeonItem.append(accordeonHeader).append(accordeonContent)
          locationsAccordeonList.append(accordeonItem)
        }

        selectCityWrapper.append(selectCity);

        selectCity.select2({
          width: 'resolve',
          theme: 'custom-theme',
          containerCssClass: 'styled-select__container',
          dropdownCssClass: 'styled-select__dropdown',
          minimumResultsForSearch: 10,
          searchInputPlaceholder: 'Search...'
        })

        countryWrapper
          .append(selectCityWrapper)
          .append(locationsAccordeon.append(locationsAccordeonList));

        $('#location-filter').append(countryWrapper)
      }

      // Add a marker clusterer to manage the markers.
      new MarkerClusterer(self.map, self.markers, {
        imagePath: 'img/icons/cluster-icons/m',
      });

      self.calculateDistances();
    }
  }

  mapApi.init();
})()