// Resize children blocks of container with class "equal-height" to be equal height
// If set attr "data-equal-from" the plugin will be worked only from it's window width
// If set attr "data-equal-targets" the plugin will resize this targets inside container,
// if not set it will resize direct childs
function resizeEqualHeight() {
  $('.equal-height').each(function () {
    var from = 0;

    if ($(this).attr('data-equal-from')) {
      from = parseInt($(this).attr('data-equal-from'), 10);

      from = isNaN(from) ? 0 : from;
    }

    var targets = $(this).attr('data-equal-targets');

    if (targets) {
      targets = $(this).find(targets);
    } else {
      targets = $(this).children();
    }

    if ($(window).outerWidth() < from) {
      targets.height('auto');

      return;
    }

    var maxHeight = Math.max.apply(
      null,
      targets.height('auto') // reset height
      .map(function () {
        return $(this).height();
      })
    );

    targets.height(maxHeight);
  });
}

$(window).on('load resize', debounce(resizeEqualHeight, 500));
// end equal-height

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}