const $mobMenuBtn = $('.mob-menu-btn');
const $header = $('.main-header');
const $mainNav = $('.main-nav');
const $secondaryNav = $('.secondary-nav');

$mobMenuBtn.on('click', function (e) {
  e.preventDefault();

  $(this).toggleClass('active')
  $header.toggleClass('menu-opened')
  $mainNav.add($secondaryNav).stop().fadeToggle()
})