var Defaults = $.fn.select2.amd.require('select2/defaults');
$.extend(Defaults.defaults, {
  searchInputPlaceholder: ''
});
var SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search');
var _renderSearchDropdown = SearchDropdown.prototype.render;
SearchDropdown.prototype.render = function (decorated) {
  // invoke parent method
  var $rendered = _renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments));
  this.$search.attr('placeholder', this.options.get('searchInputPlaceholder'));
  return $rendered;
};

// Style Select
$('.styled-select').select2({
  width: 'resolve',
  theme: 'custom-theme',
  containerCssClass: 'styled-select__container',
  dropdownCssClass: 'styled-select__dropdown',
  minimumResultsForSearch: 10,
  searchInputPlaceholder: 'Search...'
});

// Style Select
$('.styled-select-2').select2({
  width: '100%',
  theme: 'custom-theme-2',
  containerCssClass: 'styled-select__container',
  dropdownCssClass: 'styled-select__dropdown',
  minimumResultsForSearch: 10,
  searchInputPlaceholder: 'Search...'
});

function formatState(state) {
  if (!state.id) {
    return state.text;
  }

  var $state;
  if (state.element.dataset.color) {
    $state = $(
      '<span class="styled-select-option__wrapper"><span class="styled-select-option__color" style="background-color: ' + state.element.dataset.color + '"></span>' + state.text + '</span>'
    );
  } else {
    $state = $('<span class="styled-select-option__wrapper">' + state.text + '</span>');
  }
  return $state;
};

// Style Select
$('.styled-select-option').select2({
  width: 'auto',
  theme: 'custom-theme-option',
  containerCssClass: 'styled-select__container',
  dropdownCssClass: 'styled-select__dropdown',
  minimumResultsForSearch: 10,
  templateSelection: formatState,
  templateResult: formatState,
  searchInputPlaceholder: 'Search...'
});