$('.accordeon:not(.accordeon--locations)').on('click', '.accordeon__item-header', function () {
  const $parent = $(this).parent();

  if ($parent.hasClass('active')) {
    $parent
      .toggleClass('active')
      .find('.accordeon__item-content')
      .stop()
      .slideUp();
    return
  }

  $(this).closest('.accordeon').find('.accordeon__item.active')
    .removeClass('active')
    .find('.accordeon__item-content')
    .stop()
    .slideUp();

  $parent.addClass('active')
    .find('.accordeon__item-content')
    .stop()
    .slideDown();
})