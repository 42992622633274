// Flag to disable opening while dragging - "true" means MFP is allowed to open up.
let mfpOpen = true;

// Return the flag inside the disableOn option
$('.product-images a').magnificPopup({
  type: 'image',
  disableOn: function () {
    return mfpOpen;
  },
  callbacks: {
    elementParse: function(item) {
      // Function will fire for each target element
      // "item.el" is a target DOM element (if present)
      // "item.src" is a source that you may modify
      if(item.el.hasClass('product-images__list-item--video')) {
         item.type = 'iframe';
      } else {
         item.type = 'image';
      }

    }
  },
  tLoading: 'Loading image #%curr%...',
  mainClass: 'mfp-img-mobile',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
  },
  image: {
    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
  }
});

const slider = $('.product-images__list').on('destroy', function (ev, slick) {
  let carousel = $(this),
    reinit = debounce(function () {
      /*
       * slick.activeBreakpoint will tell us the breakpoint
       * at which the carousel was destroyed.
       */
      if (slick.activeBreakpoint < window.innerWidth) {
        return;
      }

      // Re-initialize with the old settings.
      carousel.slick(slick.options);

      // Remove this event listener.
      window.removeEventListener('resize', reinit);
    }, 500);

  // Assign our debounced callback to window.resize.
  window.addEventListener('resize', reinit);
}).slick({
  arrows: false,
  dots: true,
  mobileFirst: true,
  responsive: [{
    breakpoint: 767,
    settings: 'unslick'
  }]
})

// Set the flag using slick’s events  
slider.on('beforeChange', function () {
  mfpOpen = false;
});

slider.on('afterChange', function () {
  mfpOpen = true;
});

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}