// remove preloader
$(window).on('load', () => {
  $('#preloader').fadeOut('slow');
})

$(document).ready(() => {
  objectFitImages();
  svg4everybody();

  require('./accordeon.js')
  require('./account-form.js')
  require('./btn-favorite.js')
  require('./comments.js')
  require('./custom-lab.js')
  require('./map.js')
  require('./menu.js')
  require('./product-images.js')
  require('./scrolled-class.js')
  require('./styled-select.js')
  require('./tabs.js')
  require('./target-blank.js')
  require('./sliders.js')
  require('./smooth-scroll-to-hash.js')
  require('./socials.js')
  require('./shop-filter.js')
  require('./equal-height.js')
});