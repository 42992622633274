(function () {
  $('body').on('click', '.socials__add', function () {
    const parent = $(this).closest('.socials');

    if (parent.hasClass('active-more')) {
      parent.removeClass('active-more')
    } else {
      $('.socials.active-more').removeClass('active-more')
      parent.addClass('active-more');
    }
  })

  $('body').on('click', '.socials__more-close', function () {
    $(this).closest('.socials').removeClass('active-more');
  })
})()