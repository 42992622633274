(function () {
  const shopFilter = $('.shop-filter');

  $('.shop-filter__mobile-open').on('click', e => {
    shopFilter.addClass('opened');
  })

  $('.shop-filter__mobile-close, .shop-filter__overlay').on('click', e => {
    shopFilter.removeClass('opened');
  })
})()